/* eslint-disable @typescript-eslint/no-explicit-any -- TS requires `any` for certain
 * function types, so this is a legitimate usage of this. */

import globalMiddlewares from '@/middlewares/global';
import {
  ContentType,
  HTTP,
  HTTPRedirectStatus,
  HTTPStatus,
  isHTTPStatusLike
} from '@/utils/api-utils/http-statuses';
import { TimeoutError, timeoutPromise } from '@/utils/async-utils';
import { InvalidArgumentError, InvalidStateError } from '@/utils/errors';
import { xxHash32 } from 'js-xxhash';
import type {
  GetServerSideProps,
  GetStaticPaths,
  GetStaticPathsContext,
  GetStaticProps,
  NextApiHandler
} from 'next';
import type { NextFetchEvent, NextRequest } from 'next/server';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import CurrentRequestService from '@/services/isomorphic/CurrentRequestService';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import I18NService, {
  ConfiguredLocaleString
} from '@/services/isomorphic/I18NService';
import LoggerService from '@/services/isomorphic/LoggerService';
import PerformanceDiagnosticsService from '@/services/isomorphic/PerformanceDiagnosticsService';
import {
  AnyRoute,
  GetInitialProps,
  NormalizedRequestModel,
  NormalizedResponseModel,
  RouteType,
  DynamicPageHandler,
  CreatePageHandler,
  GenerateMetadataFunction
} from '@/services/models/Http';
import ServerSessionService from '@/services/serverless/ServerSessionService';
import type { Constructor, JSONObject } from '@/type-utils';
import { IHTTPErrorMetaData } from '@/utils/api-utils/IHTTPErrorMetaData';
import { removeUndefined } from '@/utils/object-utils';

import { MiddlewareExecutionError } from './MiddlewareExecutionError';
import type MiddlewareHandler from './MiddlewareHandler';

import type { createDynamicPage } from '../app-router-utils/createDynamicPage';
import type { createStaticPage } from '../app-router-utils/createStaticPage';

export { MiddlewareExecutionError };

/** Represents the function signature of a Next built-in middleware function. */
export type NextMiddleware = (
  req: NextRequest,
  ev?: NextFetchEvent
) => Promise<Response | void> | Response | void;

/** Represents the function signature of a first-party API route handler. */
export type APIHandler = () => Promise<HTTPStatus> | HTTPStatus;

/** Represents the possible overloads of `middleware`. */
interface IMiddlewareFunction {
  /**
   * Wraps any given request handler in the application with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   * @param RequestHandler - A type representing the signature of `GetServerSideProps`.
   * @param requestHandler - A function matching the signature of `GetServerSideProps`.
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware whose signature will match `GetServerSideProps`.
   */
  <RequestHandler extends GetServerSideProps>(
    requestHandler: RequestHandler
  ): RequestHandler;

  /**
   * Wraps any given request handler in the application with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   * @param RequestHandler - A type representing the signature of `GetServerSideProps`.
   * @param middlewares - An array of middlewares that should run for this route.
   * @param requestHandler - A function matching the signature of `GetServerSideProps`.
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware whose signature will match `GetServerSideProps`.
   */
  <RequestHandler extends GetServerSideProps>(
    middlewares: Array<MiddlewareHandler<Array<RouteType>>>,
    requestHandler: RequestHandler
  ): RequestHandler;

  /**
   * Wraps any given handler in the application with one that will call all global
   * middleware and set up any services needed to run this handler.
   * @param Handler - A type representing signature of `GetStaticPaths`.
   * @param handler - A function matching the signature of `GetStaticPaths`.
   * @param locale - A string representing the current locale the handler is generating
   * paths for. Example values maybe like `"en"` or `"en-US"`.
   * @returns A function wrapping the handler function which will call all necessary
   * middleware whose signature will match `GetStaticPaths`.
   */
  <Handler extends (ctx: GetStaticPathsContext) => Promise<unknown>>(
    handler: Handler,
    locale: string
  ): Handler;

  /**
   * Wraps any given request handler in the application with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   * @param RequestHandler - A type representing signature of `GetStaticProps`.
   * @param requestHandler - A function matching the signature of `GetStaticProps`.
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware whose signature will match `GetStaticProps`.
   */
  <RequestHandler extends GetStaticProps>(
    requestHandler: RequestHandler
  ): RequestHandler;

  /**
   * Wraps any given request handler in the application with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   * @param RequestHandler - A type representing signature of `GetStaticProps`.
   * @param middlewares - An array of middlewares that should run for this route.
   * @param requestHandler - A function matching the signature of `GetStaticProps`.
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware whose signature will match `GetStaticProps`.
   */
  <RequestHandler extends GetStaticProps>(
    middlewares: Array<MiddlewareHandler<Array<RouteType>>>,
    requestHandler: RequestHandler
  ): RequestHandler;

  /**
   * Wraps any given request handler in the application with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   * @param RequestHandler - A type representing signature of `NextApiHandler`.
   * @param requestHandler - A function matching the signature of `NextApiHandler`.
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware whose signature will match `NextApiHandler`.
   */
  <RequestHandler extends NextApiHandler>(
    requestHandler: RequestHandler
  ): RequestHandler;

  /**
   * Wraps any given request handler in the application with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   * @param RequestHandler - A type representing signature of `NextApiHandler`.
   * @param middlewares - An array of middlewares that should run for this route.
   * @param requestHandler - A function matching the signature of `NextApiHandler`.
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware whose signature will match `NextApiHandler`.
   */
  <RequestHandler extends NextApiHandler>(
    middlewares: Array<MiddlewareHandler<Array<RouteType>>>,
    requestHandler: RequestHandler
  ): RequestHandler;

  /**
   * Wraps any given request handler in the application with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   * @param RequestHandler - A type representing signature of `APIHandler`.
   * @param requestHandler - A function matching the signature of `APIHandler`.
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware whose signature will match `APIHandler`.
   */
  <RequestHandler extends APIHandler>(
    requestHandler: RequestHandler
  ): NextApiHandler;

  /**
   * Wraps any given request handler in the application with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   * @param RequestHandler - A type representing signature of `APIHandler`.
   * @param middlewares - An array of middlewares that should run for this route.
   * @param requestHandler - A function matching the signature of `APIHandler`.
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware whose signature will match `APIHandler`.
   */
  <RequestHandler extends APIHandler>(
    middlewares: Array<MiddlewareHandler<Array<RouteType>>>,
    requestHandler: RequestHandler
  ): NextApiHandler;

  /**
   * Wraps any given request handler in the application with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   * @param RequestHandler - A type representing signature of `NextMiddleware`.
   * @param requestHandler - A function matching the signature of `NextMiddleware`.
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware whose signature will match `NextMiddleware`.
   */
  // <RequestHandler extends NextMiddleware>(
  //   requestHandler: RequestHandler
  // ): RequestHandler;

  /**
   * Wraps any given request handler in the application with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   * @param RequestHandler - A type representing signature of `NextMiddleware`.
   * @param middlewares - An array of middlewares that should run for this route.
   * @param requestHandler - A function matching the signature of `NextMiddleware`.
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware whose signature will match `NextMiddleware`.
   */
  // <RequestHandler extends NextMiddleware>(
  //   middlewares: Array<MiddlewareHandler<Array<RouteType>>>,
  //   requestHandler: RequestHandler
  // ): RequestHandler;

  /**
   * Wraps any given request handler in the application with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   * @param RequestHandler - A type representing signature of `GetInitialProps`.
   * @param requestHandler - A function matching the signature of `GetInitialProps`.
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware whose signature will match `GetInitialProps`.
   */
  <RequestHandler extends GetInitialProps>(
    requestHandler: RequestHandler
  ): RequestHandler;

  /**
   * Wraps any given request handler in the application with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   * @param RequestHandler - A type representing signature of `GetInitialProps`.
   * @param middlewares - An array of middlewares that should run for this route.
   * @param requestHandler - A function matching the signature of `GetInitialProps`.
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware whose signature will match `GetInitialProps`.
   */
  <RequestHandler extends GetInitialProps>(
    middlewares: Array<MiddlewareHandler<Array<RouteType>>>,
    requestHandler: RequestHandler
  ): RequestHandler;

  /**
   * Wraps a route handler in a {@link createDynamicPage} function with one that
   * can accept middleware functions and will call all global middleware
   * functions.
   *
   * @param RequestHandler - A type representing a `DynamicPageHandler`.
   * @param requestHandler -  A handler function represented by the
   * `RequestHandler` type.
   *
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware and whose signature will match the initially
   * provided `DynamicPageHandler`.
   */
  <const RequestHandler extends DynamicPageHandler<unknown>>(
    requestHandler: RequestHandler
  ): RequestHandler;

  /**
   * Wraps a route handler in a "create page" (e.g. {@link createStaticPage})
   * function with one that can accept middleware functions and will call all
   * global middleware functions.
   *
   * @param RequestHandler - A type representing a `CreatePageHandler`.
   * @param requestHandler - A handler function represented by the
   * `RequestHandler` type.
   *
   * @returns A function wrapping the request handler function which will
   * call all necessary middleware and whose signature will match the initially
   * provided `CreatePageHandler`.
   */
  <const RequestHandler extends CreatePageHandler<unknown>>(
    requestHandler: RequestHandler
  ): RequestHandler;
}

/**
 * Wraps any given request handler in the application with one that
 * can accept middleware functions and will call all global middleware
 * functions.
 * @param middlewares - An array of middlewares that should run for this route.
 * @param probablyRequestHandler - The Next request handler function to wrap.
 * @returns A function wrapping the request handler function which will
 * call all necessary middleware.
 * @throws If a request handler is not provided.
 */
export const middleware: IMiddlewareFunction = <
  P,
  T extends
    | GetServerSideProps
    | GetStaticProps
    | GetStaticPaths
    | NextApiHandler
    | CreatePageHandler<P>
    | GenerateMetadataFunction
    // | NextMiddleware
    | GetInitialProps
>(
  middlewares: Array<MiddlewareHandler<[AnyRoute]>> | T,
  probablyRequestHandler?: T | string
): T => {
  // If the second argument is present and is not a string (`getStaticPaths`'s second arg
  // will be a string), then the second argument is the request handler. Otherwise the
  // first argument is the request handler.
  const requestHandler = (
    probablyRequestHandler && typeof probablyRequestHandler !== 'string'
      ? probablyRequestHandler
      : middlewares
  ) as T;

  const isGetStaticPaths = typeof probablyRequestHandler === 'string';

  // If the `requestHandler` parameter is still undefined – throw.
  if (!requestHandler || !(requestHandler instanceof Function)) {
    throw new InvalidArgumentError(
      `Expected \`requestHandler\` to be a valid request handler function. Instead received: "${requestHandler}"`
    );
  }

  // @ts-expect-error -- TS complains about the parameters of T not matching its own type.
  return async (...params: Parameters<T>): ReturnType<T> => {
    // Most of the following logic in this function can be broken into two parts -
    // 1. Build the request object that is passed to the middleware handler. This is
    // done so the middlewares don't necessarily need to worry about what kind of
    // request it is serving a middleware for.
    // 2. Call all middlewares for this wrapped route and pass in the generated
    // request object.
    // Finally, the wrapped request handler will be executed.

    // `getInitialProps` may be called on the client. If it is, return the request
    // handler immediately. In the client, the dead-code elimination and minification
    // process will remove the rest of the function body since `EnvironmentService.inBrowser`
    // is statically known at compile time.
    if ((typeof window !== "undefined")) {
      if (requestHandler instanceof Promise) {
        return requestHandler as ReturnType<T>;
      }

      // Call the wrapped request handler immediately.
      if (typeof requestHandler === 'function') {
        // @ts-expect-error -- Typescript won't spread the parameters of `T` into itself.
        return requestHandler(...params) as ReturnType<T>;
      }

      throw new MiddlewareExecutionError(
        'Received an invalid request handler.'
      );
    }

    const request = isGetStaticPaths
      ? // If the request handler is `getStaticPaths`, then the second argument will be the
        // locale string that is currently being handled.
        await NormalizedRequestModel.fromRequestParams(
          params[0] as Parameters<GetStaticPaths>[0],
          probablyRequestHandler as string
        )
      : // Else, assume the params are just the request handler params.
        await NormalizedRequestModel.fromRequestParams(...params);

    const { language: lang, country } = I18NService.getLocaleFromString(
      request.locale as ConfiguredLocaleString
    );

    const routeConfig = ConfigurationService.getConfig('routes', lang, country);

    const middlewareTimeout =
      routeConfig.getSetting('middleware.timeout').value;
    const shouldMeasurePerformance =
      routeConfig.getSetting('measurePerformance').value;

    return CurrentRequestService.use(request, async () => {
      // If the route is static an accurate URL is not available.
      const routePDS =
        shouldMeasurePerformance && !isGetStaticPaths
          ? PerformanceDiagnosticsService.get(
              'Route',
              `Req ID: "${request.id}" / URL: "${
                EnvironmentService.isStatic
                  ? EnvironmentService.baseURL
                  : EnvironmentService.url
              }"`
            )
          : null;

      const middlewaresPDS = routePDS?.get('Middlewares');

      // Store any asynchronous middleware here, so they can run in parallel.
      const asyncMiddlewares: Array<Promise<void>> = [];

      // Handles exceptions that occur when the middleware takes too long to run. It's a big
      // code smell and performance bottle neck to have long-running middleware. We want to
      // catch these issues during QA and development, but not bomb-out the request in prod.
      // In dev and QA, this will throw an exception. In production, this will only log an
      // error and possibly terminate the middleware, while still serving the request.
      const asyncMiddlewareExceptionHandler =
        (mw: MiddlewareHandler<Array<RouteType>>) =>
        async (e: Error): Promise<void> => {
          // If the middleware timedout.
          if (e instanceof TimeoutError) {
            const error = new MiddlewareExecutionError(
              `Middleware "${mw.name}" exceeded max execution time of ${middlewareTimeout}ms.`
            );
            // In production, log an error regarding the max execution time of middleware, but
            // proceed with running other middlewares.
            if ((process.env.NEXT_PUBLIC_APP_ENV === "prod")) {
              // This is logged as an error rather than a warning, because we should action on it.
              LoggerService.error(error);
            }
            // In all other environments, throw an exception.
            else {
              throw error;
            }
          }
          // If some other exception occurred, re-throw.
          else {
            throw e;
          }
        };

      // This function is used to handle the execution of a middleware for either global or
      // per-request middlewares.
      const executeMiddleware = (
        mw: MiddlewareHandler<Array<RouteType>>
      ): void => {
        const mwMeasurement = middlewaresPDS
          ?.addMeasurement(mw.name)
          ?.measure();
        try {
          // Attempt to run the middleware
          const returnValue = mw.middleware(request, request.response);

          // If the middlware can be run asynchronously, store it an array to wait on later.
          if (returnValue && returnValue instanceof Promise) {
            // Get a version of the middleware that will time out after a max execution time.
            const promise = timeoutPromise(returnValue, middlewareTimeout);

            // If the promise times out, then handle that in a way that is appropriate for
            // each environment. For instance, we might log in production but throw in dev.
            promise.catch(asyncMiddlewareExceptionHandler(mw));

            // Store the middleware so it can be run in parallel with our other async middlewares.
            asyncMiddlewares.push(promise);
          }
        } catch (e) {
          if (e instanceof HTTPStatus) {
            httpStatusHandler(request.response, e, false);
            return;
          }
          // Throw an exception if the middleware failed to execute.
          const error = e as Error;
          throw new MiddlewareExecutionError(
            `Middleware "${mw.name}" failed to execute${
              request.url && ` for route "${request.url}"`
            }. Reason: ${error.message}`,
            { cause: error }
          );
        } finally {
          mwMeasurement?.complete();
        }
      };

      // Run all global middleware handlers first.
      for (const middleware of globalMiddlewares) {
        // If no allowed routes are specified, all routes are allowed.
        // Or, if the allowed routes include this route type, run the middleware.
        if (
          !middleware.allowedRoutes ||
          middleware.allowedRoutes.includes(request.routeType)
        ) {
          executeMiddleware(middleware);
        }
      }

      /**
       * Accepts an error constructor name and a session ID and hashes them together to
       * generate a unique ID for the error. It uses the xxHash algorithm, which is
       * extremely fast and has a low chance of collisions. It's also deterministic, so
       * the same error in a given session will always generate the same ID. Every four
       * digits is dash separated. This is useful for looking up errors in the logs to
       * relate instances of errors that occur within a user's session.
       * @param errorName - The name of the error constructor.
       * @param sessionID - The ID of the session.
       * @returns A unique ID for the error.
       */
      const generateErrorID = (
        errorName: string,
        sessionID: string
      ): string => {
        const hash = xxHash32(`${errorName}-${sessionID}`, 0).toString(16);
        return hash
          .replace(/(.{4})/g, '$1-')
          .slice(0, -1)
          .toUpperCase();
      };

      // This function is used to handle when an HTTP status is either returned or
      // thrown from a response handler.
      const httpStatusHandler = <T extends RouteType>(
        res: NormalizedResponseModel<T>, // for some reason, this generic tricks TS into performing generic type narrowing
        httpStatus: HTTPStatus,
        isUncaughtError = false
      ): void => {
        let { contentType, body } = httpStatus;

        // If this is a redirect, write the "Location" header.
        if (httpStatus instanceof HTTPRedirectStatus) {
          res.setHeader('Location', httpStatus.redirectTo);
        }
        // If we're not in production and the error was an http error and was the
        // result of some other uncaught error.
        else if (
          isUncaughtError ||
          (httpStatus instanceof HTTPStatus &&
            (httpStatus.constructor.name as string).endsWith('Error'))
        ) {
          // Since the causes are nested in the JSON, they're extraneous in the stack and
          // message. Remove them from there.
          const removeCauses = (str: string): string => {
            return str.replace(/ cause: (.|\s)*$/gm, '');
          };

          let sessionID: string;

          try {
            sessionID = ServerSessionService.getSessionID();
          } catch (e) {
            // If there is no session, just use the request ID. This shouldn't be able to
            // happen, ideally, but if it does occur, we don't want it to prevent
            // processing the current error.
            sessionID = request.id;
            // For visibility, also log the error.
            LoggerService.error(e as Error);
          }

          // Serialize the error as JSON to facilitate debugging.
          const serializeErrorToJSON = (e: Error): JSONObject<true> => {
            return removeUndefined({
              name: e.constructor.name,
              sessionErrorID: generateErrorID(e.constructor.name, sessionID),
              // If the http status has a body, let's use that instead of the
              // error message and stack. However, if there is no body, then
              // we'll use the error message and stack as this is likely an
              // uncaught error or a cause of an HTTP error.
              ...(isHTTPStatusLike(e)
                ? {
                    data:
                      // If the content type is JSON, then parse the body as JSON.
                      // Otherwise, just use the body as a string.
                      e.contentType === ContentType.JSON
                        ? JSON.parse(e.body)
                        : e.body
                  }
                : e instanceof AggregateError
                  ? {
                      errors: e.errors.map((error: Error) =>
                        serializeErrorToJSON(error)
                      ),
                      stack: (process.env.NEXT_PUBLIC_APP_ENV === "prod")
                        ? undefined
                        : removeCauses(e.stack ?? '').split('\n')
                    }
                  : {
                      error: removeCauses(e.message),
                      stack: (process.env.NEXT_PUBLIC_APP_ENV === "prod")
                        ? undefined
                        : removeCauses(e.stack ?? '').split('\n')
                    }),
              cause:
                e.cause instanceof Error
                  ? serializeErrorToJSON(e.cause)
                  : undefined
            });
          };

          // Override the output to display the error and any nested errors as JSON.
          contentType = ContentType.JSON;
          body = JSON.stringify(
            // If the error is an HTTPStatus, and it has no body, then it's likely
            // that the error was thrown from a middleware, and the cause is the
            // actual error. In that case, use the cause as the error to serialize.
            serializeErrorToJSON(
              httpStatus?.cause && !httpStatus.body
                ? (httpStatus.cause as Error)
                : httpStatus
            )
          );
        }

        if (
          res.isAnyRouteType(
            RouteType.API,
            RouteType.InitialPage,
            RouteType.ServerPage
          )
        ) {
          res.rawResponse.statusCode = httpStatus.code;
          res.setHeader('Content-Type', contentType);
          res.rawResponse.write(body);
          res.rawResponse.end();
        }
      };

      try {
        // Next, run any passed in per-route middleware handlers, if there are any.
        if (middlewares instanceof Array) {
          for (const middleware of middlewares) {
            // Ensure that this middleware is applicable to this route type.
            // If any allowed routes were defined, but they do not include this route
            // type - throw.
            if (
              middleware.allowedRoutes &&
              !middleware.allowedRoutes.includes(request.routeType)
            ) {
              // If this middleware is not applicable to this route, throw an
              // error detailing the mismatch.
              throw new InvalidStateError(
                `The middleware "${middleware.name}" that was applied ${
                  request.url && `to route "${request.url}" `
                }was designed to handle routes of types ${middleware.allowedRoutes
                  .map((routeType) => `"${RouteType[routeType]}"`)
                  .join(', ')}, but the route is of type "${
                  RouteType[request.routeType]
                }".`
              );
            }

            executeMiddleware(middleware);
          }
        }

        // If any middlewares were asynchronous, wait for them before continuing.
        await Promise.all(asyncMiddlewares);

        const requestHandlerTimings = routePDS
          ?.addMeasurement('Request Handler')
          ?.measure();

        // Call the wrapped request handler after all middleware has run.
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Sadly,
         * TS believes that this value results in `any` type. */
        let reqHandlerReturnValue =
          /* eslint-disable-next-line no-nested-ternary -- Ternary to reduce
           * complexity of conditionally calling the request handler function */
          requestHandler instanceof Promise
            ? await requestHandler
            : typeof requestHandler === 'function'
              ? // @ts-expect-error -- Typescript won't spread the parameters of `T` into itself.
                requestHandler(...(params as Parameters<typeof requestHandler>))
              : null;

        // If the request handler return value was `null`, it was most likely because
        // it was neither a function nor promise.
        if (reqHandlerReturnValue === null) {
          throw new Error(
            `\`requestHandler\` returned \`null\`. This is not an acceptable return value ` +
              `for a request handler. This can also occur because the value passed to \`requestHandler\` was ` +
              `neither a function nor \`Promise\`.`
          );
        }

        // If the return value is a promise, await it so we can check if it returns
        // an HTTP status.
        if (reqHandlerReturnValue instanceof Promise) {
          reqHandlerReturnValue = await reqHandlerReturnValue;
        }

        requestHandlerTimings?.complete();

        routePDS?.logTimings();

        // If the request handler returned an HTTP status, use it to write the response.
        if (
          reqHandlerReturnValue instanceof HTTPStatus &&
          request.response.rawResponse
        ) {
          httpStatusHandler(request.response, reqHandlerReturnValue);
          return undefined;
        }

        return reqHandlerReturnValue as unknown;
      } catch (e) {
        // Catch any errors that occurred while calling the middlewares or request
        // handler. When an error occurs, we'll handle it on the basis of whether it
        // was an HTTP status, or an unexpected error.
        // Check to see if we have a usable response object.
        if (request.response.rawResponse) {
          // If the error was an HTTP Status, we'll use that to write the response.
          // If not, we'll generate a 500 error because, clearly, the error was not
          // anticipated.
          let httpStatus: HTTPStatus;
          let isUncaughtError = false;

          // If the error is an HTTP status, use it directly.
          if (e instanceof HTTPStatus) {
            httpStatus = e;
          }
          // Otherwise, if the error is an Error, and it has a `mapsToHTTPError`
          // property, and that property is a valid HTTP status, use that to
          // instantiate the HTTP status to be of that type. This is useful because some
          // uncaught errors may be indicative of a specific HTTP status, and we can
          // use this to map them to the appropriate status.
          else if (
            // Check to see if `e` is an `Error` instance. For TypeScript, treat `e` as a
            // constructor for dictionary-like object, and check to see if it has a
            // `mapsToHTTPError` property. Our custom error types extend the `Error`
            // class, and may define a `mapsToHTTPError` property to indicate which
            // HTTP status they should map to.
            e instanceof
              (Error as unknown as Constructor<Record<string, unknown>>) &&
            // Check to see if the error instance has a `mapsToHTTPError` property.
            'mapsToHTTPError' in (e as Error & IHTTPErrorMetaData) &&
            // Assert that the `mapsToHTTPError` property is a string.
            typeof e.mapsToHTTPError === 'string' &&
            // Check to ensure that `mapsToHTTPError` property is a valid HTTP status.
            (e.mapsToHTTPError as keyof typeof HTTP) in HTTP
          ) {
            // Instantiate the HTTP status to be of the type specified by the error.
            // For TypeScript, assert that the `mapsToHTTPError` property is a valid
            // HTTP status type.
            httpStatus = new HTTP[e.mapsToHTTPError as keyof typeof HTTP](
              'An unexpected error occurred.',
              {
                cause: e as unknown as Error
              }
            );

            // Signal that what was caught was an otherwise uncaught error.
            isUncaughtError = true;
          }
          // Otherwise, we don't have any particularly useful information about the error
          // that occurred, so we'll just use a generic 500 error.
          else {
            httpStatus = new HTTP.InternalServerError(
              'An unexpected error occurred.',
              {
                cause: e as Error
              }
            );

            // Signal that what was caught was an otherwise uncaught error.
            isUncaughtError = true;
          }

          // Create an error specifically for logging.
          const errorToLog = new Error(
            httpStatus.statusName,
            'cause' in httpStatus ? { cause: httpStatus.cause } : undefined
          );

          // Get a new stack trace based on the old one.
          errorToLog.stack = `${httpStatus.statusName}: ${request.method} ${
            request.url
          } \n at${httpStatus.stack
            ?.split(/\n\s+at/)
            .slice(1)
            .join('\n at')}`;

          // Log the status.
          if (httpStatus.constructor.name.endsWith('Error')) {
            LoggerService.error(errorToLog);
          } else {
            LoggerService.info(errorToLog);
          }

          // Write the response based on the HTTP status thrown.
          httpStatusHandler(request.response, httpStatus, isUncaughtError);

          // Return `undefined` to appeased ES Lint's "consistent return" rule.
          return undefined;
        }

        // If we cannot write a response for any reason, we're forced instead to
        // throw the exception instead. This is shouldn't happen so, in and of itself,
        // it inherently bears throwing.
        throw e;
      }
    }) as T extends (...args: any) => any ? ReturnType<T> : never;
  };
};
