/** Enumerates the types of routes a Normalized Request can represent. */
export enum RouteType {
  /** Represents a route in `/pages/api/`. */
  API,

  /** Represents a route using `getStaticProps`. */
  StaticPage,

  /** Represents a `getStaticPaths` handler function as a route type. */
  StaticPaths,

  /** Represents a route using `getServerSideProps`. */
  ServerPage,

  /** Represents a route using `getInitialProps`. */
  InitialPage,

  /** Represents a Next's built-in request middleware as a route type. */
  NextMiddleware,

  /**
   * Represents an API route handled by the App Router.
   * @see https://nextjs.org/docs/app/building-your-application/routing/route-handlers
   */
  AppAPIHandler,

  /**
   * Represents a route that requests a dynamically rendered page handled by the
   * App Router.
   *
   * @see https://nextjs.org/docs/app/building-your-application/rendering/server-components#dynamic-rendering
   */
  AppDynamicPage,

  /**
   * Represents a route that requests a statically pre-rendered page handled by
   * the App Router.
   *
   * @see https://nextjs.org/docs/app/building-your-application/rendering/server-components#static-rendering-default
   */
  AppStaticPage,

  /**
   * Represents an App Router Layout being rendered on the server.
   *
   * @see https://nextjs.org/docs/app/building-your-application/routing/pages-and-layouts#layouts
   */
  AppServerLayout,

  /**
   * Represents a generate metadata function used by the App Router for a
   * specific route.
   *
   * @see https://nextjs.org/docs/app/api-reference/functions/generate-metadata
   */
  GenerateMetadata
}

/** Represents any possible route. */
export type AnyRoute =
  | RouteType.API
  | RouteType.NextMiddleware
  | RouteType.ServerPage
  | RouteType.StaticPaths
  | RouteType.StaticPage
  | RouteType.InitialPage
  | RouteType.AppAPIHandler
  | RouteType.AppDynamicPage
  | RouteType.AppStaticPage
  | RouteType.GenerateMetadata
  | RouteType.AppServerLayout;

/**
 * Represents any route type that have _reduced data_. That is, routes that
 * are missing some request data such as IP address, headers, method, etc.
 *
 * These kinds of routes are used when a given context or environment cannot
 * provide the aforementioned data.
 *
 * For instance, statically-generated pages cannot provide request-specific
 * information such as headers and cookies because they are being rendered
 * before a request is even made.
 *
 * Likewise, Layouts cannot provide request-specific data because they are meant
 * to be reused between pages (and therefore requests).
 */
export type ReducedRouteTypes =
  | RouteType.GenerateMetadata
  | RouteType.StaticPage
  | RouteType.StaticPaths
  | RouteType.AppStaticPage
  | RouteType.AppServerLayout;
