import { GetStaticPaths, NextApiRequest } from 'next';
import type { NextRequest } from 'next/server';
import ShortUniqueId from 'short-unique-id';

import { DTO, Nullable, TupleTypeToUnionType } from '@/type-utils';
import { HTTPMethod } from '@/type-utils/HTTPMethod';
import { isIterable, objectToMap } from '@/utils/object-utils';

import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import LoggerService from '@/services/isomorphic/LoggerService';
import { RequestError } from '@/utils/errors';
import getRawBody from 'raw-body';
import Model from '../Model';
import { getLocale } from './helpers';
import {
  INormalizedRequest,
  RequestType
} from './interfaces/INormalizedRequest';
import { AnyRoute, RouteType } from './interfaces/RouteType';

import { ResponseType } from './interfaces/INormalizedResponse';
import NormalizedResponseModel from './NormalizedResponseModel';

const uid = new ShortUniqueId();

/** @inheritdoc */
export default class NormalizedRequestModel<T extends RouteType = AnyRoute>
  extends Model<DTO<INormalizedRequest>>
  implements INormalizedRequest
{
  /** @inheritdoc */
  public readonly id: INormalizedRequest<T>['id'];

  /** @inheritdoc */
  public readonly routeType: INormalizedRequest<T>['routeType'];

  /** @inheritdoc */
  public readonly url: INormalizedRequest<T>['url'];

  /** @inheritdoc */
  public readonly locale: INormalizedRequest<T>['locale'];

  /** @inheritdoc */
  public readonly method: INormalizedRequest<T>['method'];

  /** @inheritdoc */
  public readonly cookies: INormalizedRequest<T>['cookies'];

  /** @inheritdoc */
  public readonly queryParams: INormalizedRequest<T>['queryParams'];

  /** @inheritdoc */
  public readonly body: INormalizedRequest<T>['body'];

  /** @inheritdoc */
  public readonly headers: INormalizedRequest<T>['headers'];

  /** @inheritdoc */
  public readonly response: INormalizedRequest<T>['response'];

  /** @inheritdoc */
  public readonly custom: INormalizedRequest<T>['custom'];

  /** @inheritdoc */
  public readonly ip: INormalizedRequest<T>['ip'];

  /** @inheritdoc */
  public readonly isReduced: INormalizedRequest<T>['isReduced'];

  /**
   * @inheritdoc
   * @param request - A DTO of {@link INormalizedRequest}.
   */
  public constructor(request: DTO<INormalizedRequest>) {
    super(request);

    this.id = request.id;
    this.routeType = request.routeType;
    this.locale = request.locale;
    this.queryParams = new Map(request.queryParams);
    this.response = request.response;
    this.custom = new Map(request.custom);

    this.url = request.url as this['url'];
    this.method = request.method as this['method'];
    this.cookies = (
      request.cookies ? new Map(request.cookies) : null
    ) as this['cookies'];

    this.body = request.body as this['body'];
    this.headers = (
      request.headers ? new Map(request.headers) : null
    ) as this['headers'];

    this.ip = request.ip as this['ip'];

    this.isReduced =
      request.isReduced ||
      this.routeType === RouteType.StaticPage ||
      this.routeType === RouteType.StaticPaths;
  }

  /** @inheritDoc */
  public isRouteType<T extends RouteType>(
    routeType: T
  ): this is NormalizedRequestModel<T> {
    return this.routeType === routeType;
  }

  /** @inheritDoc */
  public isAnyRouteType<T extends Array<RouteType>>(
    ...routeTypes: T
  ): this is NormalizedRequestModel<TupleTypeToUnionType<T>> {
    return routeTypes.some((routeType) => routeType === this.routeType);
  }

  /** @inheritdoc */
  public toDTO(): DTO<INormalizedRequest> {
    const {
      id,
      routeType,
      url,
      locale,
      method,
      cookies,
      queryParams,
      body,
      headers,
      response,
      custom,
      ip,
      isReduced
    } = this;

    return {
      id,
      routeType,
      url,
      locale,
      method,
      cookies,
      queryParams,
      body,
      headers,
      response,
      custom,
      ip,
      isReduced
    };
  }

  /**
   * Constructs a `NormalizedRequestModel` from the parameters provided to the request handler.
   * Usage: A `middleware` creates a new request handler that will run all the necessary
   * middlewares and then run the user-defined callback.
   * Once it's time to run that request handler, the request handler
   * gets passed some params by Next.js. The params vary depending on the type of
   * the request. This method uses the params to determine the type of the request
   * and constructs a normalized request model.
   *
   * @param params - The parameters passed to the request handler.
   * @returns A `NormalizedResponseModel` instance.
   */
  public static async fromRequestParams(
    ...params: Parameters<RequestType> | [...Parameters<GetStaticPaths>, string]
  ): Promise<NormalizedRequestModel> {
    const [arg1, arg2] = params;

    // If there is a `request` property in the first arg, it's probably an App
    // Router "create" page handler. These come with requests already, so just
    // return that.
    if ('request' in arg1) {
      return arg1.request;
    }

    // arg1 is either the request itself, or an object that holds the request.
    const req = 'req' in arg1 ? arg1.req : arg1;

    // Extract the URL from the request, if there is one (getStaticProps won't have one).
    const url = req && 'url' in req ? req.url : null;

    let locale = '';
    let routeType: RouteType;

    const method = req && 'method' in req ? req.method : undefined;

    // Get locale and determine route type.
    // If arg1 is `GetServerSidePropsContext` or `GetStaticPropsContext`.
    if ('locale' in arg1 && arg1.locale) {
      locale = arg1.locale;

      // If the request exists but has no url, then this is a static page.
      // Url was chosen because it should always be on non-static requests.
      routeType =
        req && 'url' in req ? RouteType.ServerPage : RouteType.StaticPage;

      // If the request object does not contain cookies, then the route type
      // is actually `RouteType.InitialPage`.
      if (
        routeType === RouteType.ServerPage &&
        'req' in arg1 &&
        arg1.req &&
        !('cookies' in arg1.req)
      ) {
        routeType = RouteType.InitialPage;
      }
    }
    // If arg1 is `NextRequest`, it's from Next's built-in Middleware.
    else if (
      'nextUrl' in arg1 &&
      (arg1.nextUrl.locale ||
        arg1.nextUrl.domainLocale?.defaultLocale ||
        arg1.nextUrl.defaultLocale)
    ) {
      locale =
        arg1.nextUrl.locale ||
        arg1.nextUrl.domainLocale?.defaultLocale ||
        arg1.nextUrl.defaultLocale!;
      routeType = RouteType.NextMiddleware;
    }

    // Otherwise, if the second argument isn't the locale, and if arg1 is `NextApiRequest`
    // indicating an API route - figure out the locale.
    else if (typeof arg2 !== 'string') {
      locale = getLocale(req as NextApiRequest);
      routeType = RouteType.API;
    }
    // Check if this is possibly a `getStaticPaths` call. The locale will be the second
    // argument.
    else {
      locale = arg2;
      routeType = RouteType.StaticPaths;
    }

    // Find cookies. Null by default for static pages and `getInitialProps`.
    let cookies: Nullable<ReadonlyMap<string, string>> = null;

    // If arg1 is `GetServerSidePropsContext`.
    if ('req' in arg1 && arg1.req && 'cookies' in arg1.req) {
      cookies = objectToMap<string, string>(
        arg1.req.cookies as Record<string, string>
      );
    }
    // Else, if arg1 is `NextApiRequest | NextRequest`
    else if ('cookies' in arg1) {
      cookies = objectToMap<string, string>(
        arg1.cookies as Record<string, string>
      );
    }

    // Find the request body. Null by default for static pages.
    let body: string | null = null;

    if ((typeof window === "undefined")) {
      // If arg1 is `GetServerSidePropsContext`.
      if ('req' in arg1 && arg1.req) {
        try {
          body = await getRawBody(arg1.req, { encoding: 'utf-8' });
        } catch (cause) {
          LoggerService.error(
            new RequestError('Failed to parse request body.', { cause })
          );
        }
      }
      // If arg1 is `NextApiRequest | NextRequest`, get the body as a string.
      else if ('body' in arg1) {
        body =
          typeof arg1.body === 'object'
            ? JSON.stringify(arg1.body)
            : String(arg1.body as string);
      }
    }

    // Find the query parameters.
    let parameters: Map<string, string | Array<string>>;

    // If arg1 is `GetServerSidePropsContext | GetStaticPropsContext`.
    if ('params' in arg1 && arg1.params) {
      parameters = objectToMap<string, string | Array<string>>(
        arg1.params as Record<string, string | Array<string>>
      );
    }
    // If arg1 is `NextApiRequest | NextPageContext`.
    else if ('query' in arg1 && arg1.query) {
      parameters = objectToMap<string, string | Array<string>>(
        arg1.query as Record<string, string | Array<string>>
      );
    } else {
      parameters = new Map();
    }

    // Find the request headers. Null by default for static pages.
    let headers: Nullable<Map<string, string | Array<string>>> = null;

    // If arg1 is `NextApiRequest | NextRequest`.
    if ('headers' in arg1) {
      // If the headers are an iterable, feed it straight to a map.
      if (isIterable(arg1.headers)) {
        headers = new Map(arg1.headers);
      }
      // Else, the headers will be a record-like object. Convert to a map.
      else {
        headers = objectToMap<string, string | Array<string>>(
          arg1.headers as Record<string, string | Array<string>>
        );
      }
    }
    // If arg1 is `GetServerSidePropsContext | NextPageContext`.
    else if ('req' in arg1 && arg1.req && 'headers' in arg1.req) {
      headers = objectToMap<string, string | Array<string>>(
        arg1.req.headers as Record<string, string | Array<string>>
      );
    }

    /**
     * We first try to retrieve the IP address directly from the {@link NextRequest.ip NextRequest} if
     * it is available. Otherwise, we check if it might be set in the request headers
     * using the standard {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-For X-Forwarded-For} header.
     * Occasionally, the `X-Real-IP` header may be used to store the client IP address as well.
     * Finally, if none of those options are available, we try to read the `remoteAddress`
     * directly off the request socket.
     */
    const ip: Nullable<string> =
      (req && 'ip' in req ? req.ip : null) ??
      (headers?.get('x-forwarded-for') as Nullable<string>) ??
      (headers?.get('x-real-ip') as Nullable<string>) ??
      // eslint-disable-next-line jsdoc/require-returns -- This is an IIFE.
      /**
       * The following line no longer works because some version of Next after 13.2
       * forbids accessing properties on `req.socket` when the request is mocked.
       * But in case it's allowed in other contexts, we try anyways inside the
       * try/catch IIFE below.
       */
      // (req && 'socket' in req ? req.socket?.remoteAddress ?? null : null);
      (() => {
        try {
          return req && 'socket' in req
            ? req.socket.remoteAddress ?? null
            : null;
        } catch {
          return null;
        }
      })();

    // If the locale was not known by this point, it's possible that this route is the
    // `_error.tsx` route. Rather than make any bold assumptions about how to handle these
    // instances check for a request object in `arg1` and get the locale from the request
    // headers or fallback to the default locale. This is done because, if we don't have a
    // locale at this point, the exception that is thrown will hide the actual error that caused
    // the error page to render in the first place.
    if (!locale && 'req' in arg1 && arg1.req) {
      locale = getLocale(arg1.req);
    }

    let response: Nullable<ResponseType> = null;

    // If arg1 is `GetServerSidePropsContext | NextPageContext`.
    if ('res' in arg1 && arg1.res) {
      response = arg1.res;
    }

    // If arg2 is a response object, then this is an API route.
    if (arg2 && typeof arg2 !== 'string' && 'setPreviewData' in arg2) {
      response = arg2;
    }

    // If arg1 is a Next built-in middleware request object, get the static response.
    if ('nextUrl' in arg1) {
      // The line below is commented out for now because it actually breaks API routes
      // in a shockingly unexpected way because we can't import `NextResponse` in those
      // routes. Since we're not using Next's built-in middleware for the time being,
      // I'm going to leave this out for now. We can determine a fix when the time comes.
      // response = NextResponse;
    }

    const id = uid() as string;

    const isReduced =
      routeType === RouteType.StaticPage || routeType === RouteType.StaticPaths;

    return NormalizedRequestModel.from({
      id,
      routeType,
      url: url ?? null,
      locale,
      method: method as HTTPMethod,
      cookies,
      body,
      queryParams: parameters,
      response: NormalizedResponseModel.from({
        id,
        routeType,
        rawResponse: response,
        custom: new Map()
      }),
      headers: headers ?? null,
      custom: new Map(),
      ip,
      isReduced
    });
  }
}
